import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule, MAT_DATE_FORMATS, MatOptionModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CoreModule } from '@core/core.module';
import { ConfirmDialogComponent } from '@shared/components/confirm/confirm.dialog.component';
import { EmptyListComponent } from '@shared/components/empty-list/empty-list.component';
import { GeolocationComponent } from '@shared/components/geolocation/components/geolocation.component';
import { ListWrapperComponent } from '@shared/components/list-wrapper/list-wrapper.component';
import { MenuComponent } from '@shared/components/menu/menu.component';
import { SkeletonComponent } from '@shared/components/skeleton/skeleton.component';
import { WrapperComponent } from '@shared/components/wrapper/wrapper.component';
import { ActiveInactivePipe } from '@shared/pipes/active-inactive.pipe';
import { DefaultDatePipe } from '@shared/pipes/default-date.pipe';
import { DefaultCurrencyPipe } from '@shared/pipes/default-currency.pipe'
import { InitialsPipe } from '@shared/pipes/initials.pipe';
import { SearchPipe } from '@shared/pipes/search.pipe';
import { MatStepperModule } from '@angular/material/stepper';
import { MY_DATE_FORMATS, DayjsDateAdapter } from './dayjs-adapter';
import { ListSelectorDialog } from '@shared/components/select-items/select-items.dialog';
import { ListSelectorComponent } from '@shared/components/select-items/select-items.component';
import { LoaderComponent } from '@shared/components/loader/loader.component'
import { CollectiveRepository } from '@shared/repositories/collective.repository';
import { DistributionChannelRepository } from '@shared/repositories/distribution-channel.repository';
import { GeolocationRepository } from '@shared/repositories/geolocation.repository';
import { IdentityRepository } from '@shared/repositories/identity.repository';
import { MessageRepository } from '@shared/repositories/message.repository';
import { TrainerRepository } from '@shared/repositories/trainer.repository';
import { UserRepository } from '@shared/repositories/user.repository';


@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        FormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatOptionModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatStepperModule,
        MatSnackBarModule,
        MatToolbarModule,
        ReactiveFormsModule,
        RouterModule
    ],
    declarations: [
        ConfirmDialogComponent,
        EmptyListComponent,
        GeolocationComponent,
        ListWrapperComponent,
        MenuComponent,
        SkeletonComponent,
        WrapperComponent,
        ActiveInactivePipe,
        DefaultDatePipe,
        DefaultCurrencyPipe,
        InitialsPipe,
        SearchPipe,
        ListSelectorDialog,
        ListSelectorComponent,
        LoaderComponent,
    ],
    exports: [
        CommonModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,
        ReactiveFormsModule,
        ScrollingModule,
        ConfirmDialogComponent,
        EmptyListComponent,
        GeolocationComponent,
        ListWrapperComponent,
        MenuComponent,
        SkeletonComponent,
        MatStepperModule,
        WrapperComponent,
        ActiveInactivePipe,
        DefaultDatePipe,
        InitialsPipe,
        DefaultCurrencyPipe,
        LoaderComponent,
    ],
    providers: [
        { provide: DateAdapter, useClass: DayjsDateAdapter.bind(this), deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        CollectiveRepository,
        DistributionChannelRepository,
        GeolocationRepository,
        IdentityRepository,
        MessageRepository,
        TrainerRepository,
        UserRepository
    ]
})
export class SharedModule { }
