import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { IQueryResponse, QueryService } from '@core/services/query.service';

export interface ProfilePolicyBundle
{
    orderId: string;
    orderLineId: string;
    organizationId: string;
    collectiveId: string;
    collectiveName: string;
    name: string;
    createdAt: string;
    validFrom: string;
    validTo: string;
    policies: ProfilePolicyModel[];
    isActive: boolean;
    inWaitingPeriod: boolean;
    isClaimable: boolean;
}
  
export interface ProfilePolicyModel
{
    id: string;
    organizationId: string;
    collectiveId: string;
    collectiveName: string;
    orderId: string;
    orderLineId: string;
    policyNumber: string;
    name: string;
    productName: string;
    policyholderId: string;
    policyholderName: string;
    policyholderNationalId: string;
    beneficiaryId: string;
    beneficiaryName: string;
    beneficiaryNationalId: string;
    createdAt: string;
    validFrom: string;
    validTo: string;
    numClaims: number;
}
  
export interface ProfileOrderModel
{
    id: string;
    name: string;
    byId: string;
    byName: string;
    createdAt: string;
    orderLines: ProfileOrderLineModel[];
    allPaid: boolean;
    totalQuantity: number;
    totalPrice: number;
    totalCurrency: string;
}
  
export interface ProfileOrderLineModel
{
    id: string;
    organizationId: string;
    collectiveId: string;
    customerId: string;
    customerName: string;
    name: string;
    orderType: number;
    quantity: number;
    price: number;
    regularPrice: number;
    currency: string;
    paidAt: string;
    createdAt: string;
    orderId: string;
    orderById: string;
    orderByName: string;
    orderName: string;
    orderCreatedAt: string;
    isPaid: boolean;
    orderTypeText: string;
}
  
export interface ProfileCollectiveModel
{
    id: string;
    organizationId: string;
    organizationName: string;
    name: string;
    status: number;
    memberSince: string;
}
  
export interface ProfilePhoneModel
{ 
    id: string;
    name: string;
    number: string;
    isDefault: boolean;
    createdAt: string;
}
  
export interface ProfileUserModel
{
    id: string;
    nationalId: string;
    familyName: string;
    firstName: string;
    gender: number;
    dateOfBirth: string;
    userNbr: number;
    phoneNumber: string;
    createdAt: string;    
    isUserBlocked: boolean;
    profileStatus: boolean;
    activePolicies: number;
    hasPendingSales: boolean;
    hasActivePolicies: boolean;
    username: string;
    name: string;
}



export interface ProfileAddressModel
{
      id: string;
      name: string;
      address1: string;
      address2?:string;
      postalCode:string;
      countryCode:string;
      createdAt:string;
}
  
export interface ProfileRoleModel
{
    id: string;
    name: string;
    organizationId:string;
    organizationName:string;
    memberSince:string;
}

export interface CollectiveSummaryModel
{
    id: string;
    name: string;
    organizationName: string;
    isActive: boolean;
    totalPaid: number;
    totalPending: number;
}

export interface UserModel
{
    userId: string;
    email: string;
    nationalId: string;
    firstName: string;
    familyName: string;
    name: string;
    phoneNumber: string;
    createdAt: Date;
    collectiveId: string;
    collectiveName: string;
    collectiveStatus: number;
    memberSince: Date;
}

export interface UsersQueryModel
{
    users: UserModel[];
    totalUsers: number;
}

export interface UserPoliciesModel
{
    id: string;
    name: string;
    coveredName: string;
    isActive: boolean;
}

export interface ProfileQueryModel
{
    profile: ProfileUserModel;
    addresses: any[];
    phones: ProfilePhoneModel[];
    collective: ProfileCollectiveModel;
    roles: ProfileRoleModel[];
    orders: ProfileOrderModel[];
    bundles: ProfilePolicyBundle[];
    collectives: CollectiveSummaryModel[];
}

export interface ProfileTrainerModel
{
    profile: ProfileUserModel;
    collectives: CollectiveSummaryModel[];
}

export interface User {
    id: string;
    nationalId: string;
    name: string;
    createdAt: Date;
    phoneNumber: string;
    collectiveId: string;
    collectiveName: string;
    collectiveCode: string;
}

export interface UserSearchModel {
    users: User[];
    totalUsers: number;
}

export enum CheckIdentityStatus 
{
    NotFound = 0,
    FoundLocally = 1,
    FoundRemotely = 2,
    UnknownIdentityType = -1,
    PhoneInvalid = -2,
    PhoneNumberTaken = -3,
    UserRegisteredWithDifferentPhone = -4,
    TimeoutException = -5,
    Exception = -6
}

export enum IdTypes
{
    NationalId = 1,
    BirthCertificateNbr = 2
}

enum Gender 
{
    Undefined = 0,
    Male = 1,
    Female = 2
}

export interface CheckIdentityDetails 
{
    userId?:string;
    idNumber?: string;
    firstName?:string;
    familyName?:string;
    gender?: Gender;
    dateOfBirth?: string;
    name?: string;
    placeOfBirth?: string;
    placeOfLive?: string;
    citizenship?: string;
    genderText?: string;
}

export interface CheckIdentityResultModel
{
    idType: IdTypes;
    idNumber: string;
    status: CheckIdentityStatus;
    details: CheckIdentityDetails;
}

export interface CheckIdentityModel
{ 
    idType: number;
    idNumber: string;
    phone?: string;
    includeLocalUsers: boolean;
    userId?: string;
    createIfNotFound?: boolean
}

export interface TrainerProfileQueryModel
{
    id: string;
    name: string;
    nationalId: string;
    phoneNumber: string;
    activePolicies: number;
    hasActivePolicies: boolean;
    hasPendingSales: boolean;
}

export interface TrainerProfileQueryResult
{
    profile: TrainerProfileQueryModel;
}

export enum PII
{
    PhoneNumber,
    NationalId
}

export interface UsersQueryPayload
{
    searchString?: string;
    sortBy?: string;
    orderBy?: string;
    skip?: number;
    take?: number;
}

export interface UsersQueryResponse
{
    users: UsersQueryUserModel[];
    totalUsers: number;
}

export interface UsersQueryUserModel
{
    id: string;
    createdAt: Date;
    email: string;
    nationalId: string;
    name: string;
    phoneNumber: string;
    collectiveName: string;
}

@Injectable({
    providedIn: 'root',
  })
export class UserRepository
{
    constructor(
        private queryService: QueryService,
        private commandService: CommandService) { }


    public getUsers(data: UsersQueryPayload): Promise<IQueryResponse<UsersQueryResponse>>
    {
         return this.queryService.queryAsync<UsersQueryResponse>('UsersQuery', data);
    }

    public getUser(data: any = {}): Promise<IQueryResponse<UsersQueryModel>>
    {
         return this.queryService.queryAsync<UsersQueryModel>('UserQuery', data);
    }

    public getProfile(userId: string): Promise<IQueryResponse<ProfileQueryModel>> 
    {
        return this.queryService.queryAsync('ProfileQuery', {userId});
    }

    public getTrainerProfile(userId: string): Promise<IQueryResponse<TrainerProfileQueryResult>>
    {
        return this.queryService.queryAsync('TrainerProfileQuery', {userId})
    }

    public getPII(userId: string, pii: PII): Promise<IQueryResponse<string>> 
    {
        return this.queryService.queryAsync('PIIQuery', {userId})
    }

    public getProfileShort(userId: string): Promise<IQueryResponse<ProfileUserModel>>
    {
        return this.queryService.queryAsync('ProfileQuery', {userId, short: true});
    }

    public getProfileTrainerShort(userId: string): Promise<IQueryResponse<ProfileTrainerModel>>
    {
        return this.queryService.queryAsync('ProfileQuery', {userId, trainer: true});
    }

    public getCustomerPoliciesAsync(userId: string): Promise<any>
    {
        return this.queryService.queryAsync('CustomerPoliciesQuery', { userId });
    }

    public getUserPolicies(userId: string, queryName = 'UserPoliciesQuery'): Promise<IQueryResponse<UserPoliciesModel[]>>
    {
        return this.queryService.queryAsync(queryName, {userId});
    }

    public searchUsers(data: any = {}, queryName = 'UserSearchQuery'): Promise<IQueryResponse<UserSearchModel>>
    {
        return this.queryService.queryAsync(queryName, data);
    }

    public joinCollective(data: any): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('UserJoinCollective', data);
    }

    public setCollective(data: any): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('UserSetCollectiveByNationalId', data);
    }
    

    public transferToCollective(data: { userId: string; collectiveId: string; }): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('UserTransferToCollective', data);
    }

    public blockAsync(userId: any, blockUser: boolean): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('BlockUser', {userId});
    }

    public async getTrainers(): Promise<IQueryResponse<any[]>> 
    {
        const response = await this.queryService.queryAsync<any>('GetUsers', {role: 5});
        return response.result.users || [];
    }

    public setRole(data: {userId: string, roleId: string}): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('UserSetRole', data);
    }

    public async getUsersByRoles(roleIds: string[]): Promise<any[]> 
    {
        const response = await this.queryService.queryAsync<any>('GetUsersByRole', {roleIds});
        return response.result.users || [];
    }

    public async checkIdentityAsync(data: CheckIdentityModel): Promise<IQueryResponse<CheckIdentityResultModel>>
    {
        return await this.queryService.queryAsync<any>('CheckIdentity', data);
    }

    public async getUsersByCollective(collectiveId: string): Promise<any[]> 
    {
        const response = await this.queryService.queryAsync<any>('GetUsersByCollective', {collectiveIds: [collectiveId]});
        return response.result.users || [];
    }

    public async getUserByPhone(phoneNumber: string): Promise<any> 
    {
        const response = await this.queryService.queryAsync<any>('GetUserByPhone', {phoneNumber});
        if (response.isSuccess) {
            return response.result.user || null;
        }
        return null;
    }

    public createUser(data: any): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('UserCreate', data);
    }

    public changePassword(data: { nationalId: string, phone: string, password: string }): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('UserUpdatePassword', data);
    }

    public IprsReconciliation(data: any): Promise<ICommandResponse>
    {   
        return this.commandService.publishAsync('IprsReconciliation', data);
    }


    public saveProfile(data: any): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('UserUpdate', data);
    }

    public savePhoneNumber(data: any): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('PhoneSave', data);
    }

    public saveAddressAsync(data: any): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('AddressSave', data);
    }



}
