<mat-dialog-content>
    <div class="tw-flex tw-justify-between tw-items-center">
        <h3 mat-dialog-title class="">Selector</h3>

        <mat-form-field appearance="outline">
            <mat-label>Search</mat-label>
            <input type="search" matInput placeholder="Search" name="search" [(ngModel)]="searchText">
            <button matSuffix mat-button class="is-primary"><mat-icon>search</mat-icon></button>
        </mat-form-field>
    </div>

    <ng-container *ngIf="(items | search : searchText); else loading">

        <div class="pv-15">

            <table class="table pv-15" *ngIf="(items | search : searchText).length > 0; else noResult">

                <tbody>
                    <tr [class.selected]="isSelected(item.id)" (click)="toggle(item.id, item.name)"
                        *ngFor="let item of items | search : searchText;">
                        <td>{{item.name}}</td>
                        <td class="text-right">
                            <mat-radio-button *ngIf="!data.multipleSelection" [checked]="isSelected(item.id)"
                                (change)="toggle(item.id, item.name)"></mat-radio-button>
                            <mat-checkbox *ngIf="data.multipleSelection" [checked]="isSelected(item.id)"
                                (change)="toggle(item.id, item.name)"></mat-checkbox>
                        </td>
                    </tr>
                </tbody>

            </table>

        </div>

    </ng-container>

    <ng-template #noResult>
        <p>Empty list</p>
    </ng-template>

    <ng-template #loading>
        <p>Loading</p>
    </ng-template>


</mat-dialog-content>

<mat-dialog-actions class="tw-flex tw-justify-between tw-w-full">

    <button type="button" mat-button class="btn is-primary mr-5" (click)="submit($event)">
        OK
    </button>

    <span class="ml-2"></span>

    <button type="button" mat-button mat-dialog-close>
        Cancel
    </button>

</mat-dialog-actions>