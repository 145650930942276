import { Injectable } from '@angular/core';
import { CommandService } from '@core/services/command.service';
import { IQueryResponse, QueryService } from '@core/services/query.service';

export interface TrainerProfileModel
{
    id: string;
    userNbr: string;
    familyName: string;
    firstName: string;
    gender: number;
    dateOfBirth: Date;
    createdAt: Date;
    name: string;
}


export interface PayoutHistoryQueryModel
{
    searchString?: string;
    skip: number;
    take: number;
    sortBy?: string;
    direction?: string;
}


export interface PayoutHistoryQueryResult
{
    trainers: TrainerModel[];
    totalCount: number;
}

export interface TrainerModel
{
    id?: string;
    name?: string;
    payout?: number;
}

export interface TrainerDashboardQueryResult
{
    collectives: TrainersCollectivesModel[];
    pendingCbiGroups: number;
}

export interface TrainersCollectivesModel
{
    id: string;
    name: string;
    lastPayoutAt: Date;
    isActive: boolean;
    totalPaid: number;
    totalPending: number;
}

@Injectable({providedIn: 'any'})
export class TrainerRepository
{
    constructor(
        private commandService: CommandService,
        private queryService: QueryService,
    ) {}

    public async getTrainers(data: any): Promise<any>
    {
        const response = await this.queryService.queryAsync('CbiTrainersQuery', data);
        return response.result;
    }

    public async getTrainer(CbiTrainerId: any): Promise<any>
    {
        const response = await this.queryService.queryAsync('CbiTrainerQuery', { CbiTrainerId });
        return response.result;
    }

    public async getTrainerProfile(): Promise<IQueryResponse<TrainerProfileModel>>
    {
        return await this.queryService.queryAsync('TrainerQuery');
    }

    public async getPayoutHistory(data: PayoutHistoryQueryModel): Promise<IQueryResponse<PayoutHistoryQueryResult>>
    {
        return await this.queryService.queryAsync('PayoutHistoryQuery', data);
    }

    public async getTrainerDashboard(): Promise<IQueryResponse<TrainerDashboardQueryResult>>
    {
        return await  this.queryService.queryAsync('TrainerDashboardQuery');
    }

    public async action(data): Promise<any>
    {
        return await this.commandService.publishAsync('CreateCbiTrainerAction', data);
    }
}
