import { Injectable } from '@angular/core';
import { IQueryResponse, QueryService } from '@core/services/query.service';

export interface MessagesQueryResponse
{
    messages: MessageEntity[];
    totalMessages: number;
}

export interface MessageEntity
{
    id: string;
    processedAt: string;
    senderId: string;
    senderName: string;
    subject: string;
    body: string;
}

export interface MessagesQueryModel
{
    userId?: string;
    skip?: number;
    take?: number;
}

@Injectable({ providedIn: 'root' })
export class MessageRepository
{
    constructor(private queryService: QueryService) { }

    public getMessages(data: MessagesQueryModel): Promise<IQueryResponse<MessagesQueryResponse>>
    {
        return this.queryService.queryAsync('MessagesQuery', data);
    }
}
