import { Component, Input } from '@angular/core';
import { IMenuItem } from '@shared/components/menu/menu.component';

@Component({
    selector: 'list-wrapper',
    templateUrl: 'list-wrapper.component.html'
})
export class ListWrapperComponent
{
    @Input() public title: string;
    @Input() public supertitle: string;
    @Input() public subtitle: string;
    @Input() public returnUrl: string;
    @Input() public menu?: IMenuItem[] = null;

    public async doClickAsync(item: IMenuItem): Promise<void>
    {
        if (item.onClick)
        {
            item.onClick();
        }
    }
}
