import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';

export interface Identity
{
    token: string;
    sessionId: string;
    userId: string;
    name: string;
    nationalId: string;
    countryId: string;
    isRoot: boolean;
    exp: number;
    iat: number;
    nbf: number;
    trainerPayout: number;
}

@Injectable({providedIn: 'root'})
export class TokenService
{
    private _jwtHelperService = new JwtHelperService(null);

    constructor(private cookieService: CookieService) {}

    public get token(): string | null
    {
        return this.getToken();
    }
    
    public get isAuth(): boolean
    {
        if(this.token == null || this._jwtHelperService.isTokenExpired(this.token, 2)) return false;
        return true;
    }

    public set(token?: string | null | undefined): void
    {
    
        this.storeToken(token);
    }

    public getIdentity(): Identity
    {
        const t = this.token  ||undefined;
        return this._jwtHelperService.decodeToken(t);
    }

    public getUserId(): string | null
    {
        return this.getIdentity()?.userId || null;
    }

    public getSessionId(): string | null
    {
        return this.getIdentity()?.sessionId || null;
    }

    public getTrainerPayout(): number | null
    {
        return this.getIdentity()?.trainerPayout || null;
    }

    private storeToken(token: string | null | undefined) 
    {
        const expires = this._jwtHelperService.getTokenExpirationDate(token);
        if(token) this.cookieService.set('session', token, expires, undefined, undefined, true);
        else this.cookieService.delete('session'); // sign out
    }

    private getToken(): string| undefined
    {  
        return this.cookieService.get('session');
    }


    public getCountryId(): string | null
    {
        return this.getIdentity()?.countryId || null;
    }

    public getIsRoot(): boolean
    {
        return this.getIdentity()?.isRoot || false;
    }
}
