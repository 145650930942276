import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ListSelectorDialog, ListSelectorDialogData } from './select-items.dialog';
import { AbstractControl, ControlContainer, ControlValueAccessor, UntypedFormControl, FormControlDirective, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface ListSelectorConfig {
    queryName: string,
    queryParams?: any,
    responseProp?: string,
    txtNoSelection?: string
}

@Component({
    selector: 'ddf-list-selector',
    template: `
        <button type="button" mat-button class="select-button" (click)="openAsync()">
            {{btnText}}
        </button>
    `,
    styles: [`
        .select-button {
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            padding: 8px !important;
            text-align: left !important;
            text-transform: initial !important;
            width: 100% !important;
            box-sizing: border-box !important;
            margin-bottom: 1.2rem;
            border: 1px solid #ddd;
            color: inherit;
        }
    `],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: ListSelectorComponent,
        multi: true
    }],
    inputs: ['formControlName', 'formControl', 'config']
})
export class ListSelectorComponent implements ControlValueAccessor
{
    @ViewChild(FormControlDirective)
    public formControlDirective: FormControlDirective;
    public formControl: UntypedFormControl;
    public formControlName: string;
    private items: {id: string, name: string}[] = [];
    private config: ListSelectorConfig | undefined;

    public get control(): AbstractControl {
        return this.formControl ||
            this.controlContainer.control.get(this.formControlName);
    }

    public get btnText(): string
    {
        return this.items.length <= 0
            ? this.config?.txtNoSelection || 'No Selection'
            : this.items.map((x: any) => x.name).join(',');
    }

    constructor(
        private controlContainer: ControlContainer,
        private dialog: MatDialog) {}

    registerOnTouched(fn: any): void {
        if (!this.formControlDirective) return;
        this.formControlDirective.valueAccessor.registerOnTouched(fn);
    }

    registerOnChange(fn: any): void {
        if (!this.formControlDirective) return;
        this.formControlDirective.valueAccessor.registerOnChange(fn);
    }

    writeValue(obj: any): void
    {
        if (!obj && this.control)
        {
            this.items = [];
        }

        if (!this.formControlDirective) return;
        this.formControlDirective.valueAccessor.writeValue(obj);
    }

    setDisabledState(isDisabled: boolean): void {
        if (!this.formControlDirective) return;
        this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
    }

    public async openAsync(): Promise<void>
    {
        const data: ListSelectorDialogData = {
            queryName: this.config.queryName,
            queryParams: this.config.queryParams,
            responseProp: this.config.responseProp,
            selected: Array.from(this.items)
        };

        const response = await this.dialog
            .open(ListSelectorDialog, {
                data
            })
            .afterClosed()
            .toPromise();

        if (response)
        {
            this.items = response;
            const ids = this.items.map((x: any) => x.id);
            this.control.setValue(ids);
        }
    }
}
